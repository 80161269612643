<template>
  <v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step
        :complete="e1 > 1"
        step="1"
      >
        Datalogger
      </v-stepper-step>

      <v-divider />

      <v-stepper-step
        :complete="e1 > 2"
        step="2"
      >
        Cihaz tipi
      </v-stepper-step>

      <v-divider />

      <v-stepper-step step="3">
        Veri
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-row
          class="pt-4"
          align="center"
        >
          <v-col
            cols="12"
            sm="6"
          >
            <v-select
              v-model="santralModel"
              outlined
              :items="santralList"
              :menu-props="{maxHeight: '300'}"
              label="santral"
              dense
              hide-details
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-select
              v-model="dataloggerModel"
              outlined
              :items="dataloggerList"
              :menu-props="{maxHeight: '300'}"
              label="Datalogger"
              dense
              hide-details
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-select
              v-model="inverterTypeModel"
              :items="inverterTypeList"
              :menu-props="{maxHeight: '300'}"
              label="Cihaz Listesi"
              outlined
              dense
              hide-details
            />
          </v-col>
        </v-row>
        <v-col>
          <v-btn
            color="primary"
            dense
            outlined
            @click="e1 = 2"
          >
            Sonraki
          </v-btn>
        </v-col>
      </v-stepper-content>
      <v-stepper-content step="2">
        <v-row
          class="pt-4"
          align="center"
        >
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="formdata.inverterDeviceSerial"
              label="Seri Numarası"
              outlined
              dense
              hide-details
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="formdata.INVERTERNAME"
              label="İnverter adı"
              outlined
              hide-details
              dense
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="formdata.ID"
              label="id (1)"
              outlined
              dense
              hide-details
            />
          </v-col>

          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="formdata.installedDcPower"
              label="DC Gücü"
              outlined
              hide-details
              dense
            />
          </v-col>

          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="formdata.ModuleQuantity"
              label="Modul Sayısı"
              outlined
              dense
              hide-details
            />
          </v-col>

          <v-col
            cols="12"
            sm="4"
          >
            <v-select
              v-model="formdata.SENSOR"
              :items="sensorlist"
              :menu-props="{maxHeight: '300'}"
              label="SENSOR"
              outlined
              dense
              hide-details
              multiple
            />
          </v-col>

          <!-- <v-col cols="12" sm="3">
            <v-select
              v-model="formdata.inverterStatus"
              :items="['Active', 'Passive']"
              :menu-props="{ maxHeight: '300' }"
              label="Durum"
              outlined
              dense
              hide-details
            />
          </v-col> -->
        </v-row>
        <div class="ml-4 mr-5">
          <v-switch
            v-model="inverterStatus"
            label="Durum"
            hide-details
            inset
          />
        </div>
        <v-col class="col-12 mt-4">
          <v-btn
            color="primary"
            dense
            outlined
            @click="e1 = 3"
          >
            Sonraki
          </v-btn>
        </v-col>
      </v-stepper-content>

      <v-stepper-content step="3">
        <v-row class="mb-0 mt-0 pt-0 pb-0 ml-5 mr-5">
          <v-col cols="12">
            <v-data-table
              v-model="deviceParameterModel"
              :headers="devheaders"
              :items="deviceParameterList"
              class="elevation-1"
              dense
              item-key="value"
              show-select
              :items-per-page="itemPerPage"
              hide-details
            />
          </v-col>
          <v-col
            cols="12"
            class="d-flex"
          >
            <v-col cols="2">
              <v-btn
                color="primary"
                dense
                outlined
                @click="e1 = 1"
              >
                Sonraki
              </v-btn>
            </v-col>
            <v-col cols="1">
              <v-btn
                dense
                outlined
                @click="setInverterCreate"
              >
                Kaydet
              </v-btn>
            </v-col>
          </v-col>
        </v-row>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import { getDeviceList } from '@/api/Request/farm';
import { getDataloggerList } from '@/api/Request/datalogger';
import { getInverterTypeList, setInverter } from '@/api/Request/inverter';
import { getSensorList } from '@/api/Request/sensor';

import helper from '@/api/helper';

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['inverterdata', 'santralData', 'santralList'],
  data: () => ({
    e1: 1,
    deviceTypeModel: 'INVERTER',
    inverterTypeModel: '',
    inverterTypeList: [],
    inverterTypeData: [],
    itemPerPage: 10,
    deviceParameterList: [],
    deviceParamHeader: [],
    deviceParameterModel: [],
    dataloggerList: [],
    devheaders: [],
    dataloggerModel: '',
    deviceList: [],
    deviceModel: '',
    dataloggerData: [],
    sensorlist: [],

    santralModel: '',
    inverterStatus: true,
    formdata: {},
  }),
  watch: {
    deviceTypeModel() {
      // this.getInverterTypeList();
    },
    deviceParameterModel() {},
    dataloggerModel() {
      this.getInverterTypeList();
    },
    inverterTypeModel() {
      if (this.inverterTypeModel !== null) {
        this.getInverterType();
      }
    },

    santralModel() {
      if (this.santralList.length > 0) {
        this.formdata.santralCode = this.santralData[this.santralModel].santralCode;
        this.formdata.santral = this.santralData[this.santralModel].santral;
        this.getDatalogger();
      }
    },
    inverterdata() {
      this.setFormNew();
      this.santralModel = '';
      this.dataloggerModel = '';
      this.inverterTypeModel = '';
      this.santralData = {};

      this.deviceParameterList = [];
      this.deviceParameterModel = [];
      this.e1 = 1;
      this.deviceTypeModel = 'INVERTER';
      this.deviceModel = '';
      this.setInverterDataFunc();
    },
  },
  created() {
    setTimeout(() => {
      const storage = window.localStorage;
      const userCompany = JSON.parse(window.atob(storage.getItem('user_company')));
      this.companyCode = userCompany.companyCode;
      this.prefix = userCompany.prefix;

      this.setFormNew();
      this.setInverterDataFunc();
    }, 100);
  },
  mounted() {},
  methods: {
    setFormNew() {
      this.formdata = {
        ID: '',
        installedDcPower: '',
        ModuleQuantity: '',
        inverterStatus: 'Active',
        SENSOR: [],
        santral: '',
        datalogger: '',
        inverterType: '',
        device: '',
        deviceParameter: [],
      };
    },

    dialogClose() {
      this.santralModel = '';
      this.dataloggerModel = '';
      this.dialog = false;
      this.e1 = 1;
    },
    setInverterDataFunc() {
      if (this.inverterdata.general_measure_list !== undefined) {
        this.devheaders = [
          { text: 'text', value: 'value' },
          { text: 'key', value: 'key' },
          { text: 'unit', value: 'unit' },
          { text: 'type', value: 'type' },
          { text: 'formuletype', value: 'formuletype' },
        ];
        this.deviceParameterList = this.inverterdata.general_measure_list;
        this.deviceParameterModel = this.inverterdata.general_measure_list;
        this.inverterTypeModel = this.inverterdata.inverterTypeDeviceId;
        this.formdata = { ...{}, ...this.inverterdata };
        // eslint-disable-next-line eqeqeq
        this.inverterStatus = this.inverterdata.inverterStatus == 'Active';

        this.santralModel = this.inverterdata.santralCode;
        this.dataloggerModel = this.inverterdata.dataloggerDeviceModel;
        this.getSensor_List();
      }
    },
    getSensor_List() {
      const params = {
        condiniton: {
          prefix: this.prefix,
        },
      };

      getSensorList(params).then((res) => {
        if (res.data.success === 'true') {
          if (res.data.data.length > 0) {
            this.sensorlist = [];
            Object.keys(res.data.data).forEach((key) => {
              this.sensorlist.push({
                text: res.data.data[key].SENSORNAME,
                value: res.data.data[key].SENSOR,
              });
            });
          }
        }
      });
    },

    // datalogger listesi
    getDatalogger() {
      const params = {
        condiniton: {
          prefix: this.prefix,
          santral: this.santralData[this.santralModel].santral,
        },
      };

      getDataloggerList(params).then((res) => {
        const devList = [];
        const deviceList = {};
        this.dataloggerData = deviceList;
        this.dataloggerList = devList;
        if (helper.resIsEmpty(res)) {
          Object.keys(res.data.data).forEach((i) => {
            deviceList[res.data.data[i].DeviceModel] = res.data.data[i];
            devList.push({
              text: `${res.data.data[i].deviceName}`,
              value: `${res.data.data[i].DeviceModel}`,
            });
          });
        }
        this.dataloggerData = deviceList;
        this.dataloggerList = devList;
      });
    },

    /// / inverter tipleri listesi
    getInverterTypeList() {
      const params = {
        condiniton: {
          DeviceType: this.deviceTypeModel,
        },
      };

      getDeviceList(params).then((res) => {
        const devList = [];
        /// DeviceModel
        const deviceData = {};

        Object.keys(res.data.data).forEach((i) => {
          const dev = res.data.data[i].DeviceId;
          deviceData[dev] = res.data.data[i];
          devList.push({
            text: `${res.data.data[i].DeviceManufacture} ${res.data.data[i].DeviceModel}`,
            value: res.data.data[i].DeviceId,
          });
        });

        // this.inverterTypeModel = deviceData;
        this.deviceData = deviceData;
        this.inverterTypeList = devList;
      });
    },

    getInverterType() {
      this.devheaders = [
        { text: 'text', value: 'text' },
        { text: 'key', value: 'key' },
        { text: 'unit', value: 'unit' },
        { text: 'type', value: 'type' },
        { text: 'formuletype', value: 'formuletype' },
      ];

      if (helper.is_Empty(this.dataloggerModel)) {
        return;
      }

      if (helper.is_Empty(this.deviceData)) {
        return;
      }

      const params = {
        condiniton: {
          INVERTERMODEL: this.deviceData[this.inverterTypeModel].Model,
        },
      };

      if (this.dataloggerData[this.dataloggerModel] !== undefined) {
        const temp = this.dataloggerData[this.dataloggerModel];
        params.condiniton.Datalogger = temp.Model;
      }

      getInverterTypeList(params).then((res) => {
        this.deviceParameterList = res.data.data[0].general_measure_list;
        this.deviceParameterModel = res.data.data[0].general_measure_list;
        this.itemPerPage = this.deviceParameterList.length;
      });
    },
    setInverterCreate() {
      // eslint-disable-next-line radix

      const temp = this.dataloggerData[this.dataloggerModel];
      this.formdata.INVERTER = this.formdata.ID < 10 ? `INVERTER0${this.formdata.ID}` : `INVERTER${this.formdata.ID}`;
      const data = {
        INVERTERNAME: this.formdata.INVERTERNAME,
        ID: this.formdata.ID,
        INVERTER: this.formdata.INVERTER,
        installedDcPower: this.formdata.installedDcPower,
        inverterDeviceSerial: this.formdata.inverterDeviceSerial,
        SENSOR: this.formdata.SENSOR,
        ModuleQuantity: this.formdata.ModuleQuantity,
        inverterStatus: this.inverterStatus === true ? 'Active' : 'Passive',
        inverterTypeDeviceId: this.deviceData[this.inverterTypeModel].DeviceId,
        INVERTERMODEL: this.deviceData[this.inverterTypeModel].Model,
        inverterManufacture: this.deviceData[this.inverterTypeModel].DeviceManufacture,
        inverterDeviceModel: this.deviceData[this.inverterTypeModel].DeviceModel,
        general_measure_list: this.deviceParameterModel,
        dataloggerName: temp.deviceName,
        dataloggerDeviceModel: temp.DeviceModel,
        dataloggerTypeDeviceId: temp.DeviceId,
        santral: temp.santral,
        santralCode: temp.santralCode,
        timeInterval: temp.timeInterval,
      };

      const params = {
        condiniton: {
          companyCode: this.companyCode,
          prefix: this.prefix,
          deviceType: this.deviceTypeModel,
          INVERTER: data.INVERTER,
          santral: data.santral,
          santralCode: data.santralCode,
        },
        data,
      };

      setInverter(params).then(() => {
        // console.log(res);
        this.$emit('inverterlist');
      });
    },
  },
};
</script>
